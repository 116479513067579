import React, { useState, useContext, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Context } from "../../screenContext";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { showLoader, hideLoader } from "../../actions/application";
import Android from "../../images/tech-logo/android-logo.svg";
import Exchange from "../../images/app-logo/exchange.png";
import SelectedApp from "../selected-app/selected-app";
import Tap from "../../images/app-logo/tap.png";
import Liberty from "../../images/app-logo/liberty.png";
import Project from "./project/project";
import { connect } from "react-redux";
import { Grid, Container } from "@mui/material";
import "./projects.css";

const Projects = (props) => {
  const [count, setCount] = useState(0);
  if (count === 0) {
    props.dispatch(showLoader());
  }
  let match = useRouteMatch();
  const project_list = [];

  useEffect(() => {
    setTimeout(() => {
      props.dispatch(hideLoader());
    }, 2000);
  });

  const handleLoader = () => {
    const ready = count + 1;
    if (ready === project_list.length) {
      setTimeout(() => {
        props.dispatch(hideLoader());
      }, 1500);
    }
    setCount(ready);
  };

  const screen = useContext(Context);

  for (const [index, value] of APPS.entries()) {
    project_list.push(
      <Project
        key={index}
        details={value}
        match={match}
        loaded={handleLoader}
      />
    );
  }

  return (
    <Switch>
      <Route path={`${match.path}/:appTitle`}>
        <SelectedApp />
      </Route>
      <Route path={match.path}>
        <div className="container">
          <Button
            className="button"
            variant="outline-light"
            href="https://github.com/daryl-crosbie"
            target="_blank"
            rel="noopener noreferrer"
            size={screen.width > 1200 ? "lg" : screen.height > 450 ? "" : "sm"}
          >
            GitHub Repository
          </Button>

          <Container className="projects">
            <Grid container spacing={10}>
              {project_list.map((app, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  {app}
                </Grid>
              ))}
            </Grid>
          </Container>
        </div>
      </Route>
    </Switch>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(Projects);
const APPS = [
  {
    title: "Liberty",
    logo: Liberty,
    platform: "Android / iOS",
    platform_logo: Android,
  },
  {
    title: "D-Tap",
    logo: Tap,
    platform: "Android",
    platform_logo: Android,
  },
  {
    title: "D-Exchange",
    logo: Exchange,
    platform: "Android",
    platform_logo: Android,
  },
];
