import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showLoader, hideLoader } from "../../actions/application";
import { connect } from "react-redux";
import ContactForm from "./form";
import Hovering from "./hover";
import "./contact.css";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hovering: 0 };
    props.dispatch(showLoader());
    setTimeout(() => {
      props.dispatch(hideLoader());
    }, 1000);
  }
  render() {
    return (
      <div className="outer-container">
        <div className="contact">
          <h1>Contact me</h1>
          <br />
          <ContactForm />
        </div>
        <div className="social-links">
          <div className="inner-links">
            <a
              href="https://www.facebook.com/profile.php?id=100089616676955"
              target="_blank"
              rel="noopener noreferrer"
              style={
                this.state.hovering === 1 ? Hovering.facebook : Hovering.normal
              }
              onMouseEnter={() => {
                this.setState({ hovering: 1 });
              }}
              onMouseLeave={() => this.setState({ hovering: 0 })}
            >
              <FontAwesomeIcon icon={["fab", "facebook"]} />
            </a>
            <a
              href="https://www.linkedin.com/in/daryl-crosbie-34b5bb17b/"
              target="_blank"
              rel="noopener noreferrer"
              style={
                this.state.hovering === 2 ? Hovering.linkedin : Hovering.normal
              }
              onMouseEnter={() => {
                this.setState({ hovering: 2 });
              }}
              onMouseLeave={() => this.setState({ hovering: 0 })}
            >
              <FontAwesomeIcon icon={["fab", "linkedin"]} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(Contact);
