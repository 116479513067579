import React, { useState, useContext } from "react";
import { Context } from "../screenContext";
import Home_Lan from "../images/homeLan.jpg";
import Home_Por from "../images/homePor.jpg";
import LogoWhite from "../images/logoWhite.png";
import LogoBlack from "../images/logoBlack.png";
import { showLoader, hideLoader } from "../actions/application";
import { useDispatch } from "react-redux";

export default function Home() {
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);
  if (!ready) {
    dispatch(showLoader());
  }
  const screen = useContext(Context);
  const landscape = screen.width > screen.height;

  const logoStyle = {
    position: "absolute",
    left: "20%",
    zIndex: "10",
    maxWidth: "500px",
    width: landscape ? "15vw" : "15vh",
    top: landscape ? "25%" : "5%",
  };

  const handleLoader = () => {
    setTimeout(() => {
      dispatch(hideLoader());
    }, 1000);
    setReady(true);
  };
  return (
    <div style={{ zIndex: "1", position: "relative", top: "10vh" }}>
      <img
        style={{ width: "100%", height: "90vh", }}
        onLoad={handleLoader}
        src={landscape ? Home_Lan : Home_Por}
        alt="background_image"
      />
      <img style={logoStyle} src={landscape ? LogoWhite : LogoBlack} alt="" />
    </div>
  );
};

