import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Stack } from "@mui/material";
import "./project.css";

const StyledProductImg = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

export default function Project(props) {
  return (
    <Link className="link" to={`${props.match.url}/${props.details.title}`}>
      <div className="project">
        <Box>
          <StyledProductImg
            alt="logo"
            src={props.details.logo}
            onLoad={props.loaded}
          />
        </Box>

        <Stack spacing={3} sx={{ p: 3 }}>
          <p className="title">{props.details.title}</p>

          <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-between"
          >
            <img
              className="platform-logo"
              src={props.details.platform_logo}
              alt="platform-logo"
              onLoad={props.loaded}
            />

            <p className="platform-text">{props.details.platform}</p>
          </Stack>
        </Stack>
      </div>
    </Link>
  );
}
