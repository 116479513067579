const props = {
  normal: {
    color: "white",
  },
  facebook: {
    color: "#3b5998",
  },
  linkedin: {
    color: "#006192",
  },
};

export default props;
