import React, { useState } from "react";
import { useWindowHeight, useWindowWidth } from "./screenSize";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { showLoader, hideLoader } from "./actions/application";
import { ContextProvider } from "./screenContext";
import LoaderView from "./components/loader/LoaderView";
import Home from "./pages/home";
import Header from "./components/header/header";
import About from "./pages/about/about";
import Projects from "./pages/projects/projects";
import Contact from "./pages/contact/contact";
import { connect } from "react-redux";
import Matrix from "./images/matrix1920.jpg";
import "./App.css";
import DeleteUserAccount from "./pages/deleteUserAccount";

const App = (props) => {
  props.dispatch(showLoader());
  const [started, setStarted] = useState(false);

  const handleStart = () => {
    setStarted(true);
  };

  const screen = { width: useWindowWidth(), height: useWindowHeight() };
  if (started) {
    props.dispatch(hideLoader());
    return (
      <Router>
        <ContextProvider value={screen}>
          <div className="App" style={{ width: screen.width }}>
            <Header className="header" />
            <div className="background">
              <img className="" src={Matrix} alt="background" />
            </div>
            <LoaderView start={started} />

            <Switch>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/projects">
                <Projects />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/delete-user-account">
                <DeleteUserAccount />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </ContextProvider>
      </Router>
    );
  }
  return <LoaderView loaded={handleStart} />;
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(App);
