import React from "react";
import ControlledCarousel from "./carousel";
import "./app-img.css";
import { de1, de2, de3, de4, de5, de6 } from '../../../images/d-exchange/index'
import { dt1, dt2, dt3, dt4 } from "../../../images/d-tap/index";
import { L1, L2, L3, L4, L5}  from "../../../images/liberty/index";


export default function AppImg(props) {
  var count;
  var img;
  SS.forEach((element) => {
    if (element.title === props.appTitle) {
      img = element.images;
      count = element.images.length
    }
  });
  return (
    <div className="app-img">
      <div className="app-img-inner-container">
        <ControlledCarousel images={img} loader={() => props.loader(count)} />
      </div>
    </div>
  );
}
const SS = [
  { title: 'Liberty', images: [L1, L2, L3, L4, L5] },
  { title: "D-Exchange", images: [de1, de2, de3, de4, de5, de6] },
  { title: "D-Tap", images: [dt1, dt2, dt3, dt4] },
];
