import React from "react";
import { connect } from "react-redux";
import codeGif from '../../images/matrix-code.gif';
import "./loader.css";

const LoaderView = (props) => {
  const { loading } = props;
  if (!loading) return null;
  
  if (props.start) {
    return (
      <div className="loader-container">
        <a
          className="attibution"
          href="www.textures4photoshop.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Textures4Photoshop.com
        </a>
      </div>
    );
  }
  return (
    <div className="initial-container">
      <img src={codeGif} alt="Error" onLoad={props.loaded} style={{opacity: "0"}}/>
    </div>
  ); 
};
const mapStateToProps = (state) => ({ loading: state.application.loading });
export default connect(mapStateToProps)(LoaderView);
