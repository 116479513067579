const initialState = {
  data: {},
  loading: false,
};

const loadingOverlay = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW":
      return { state, loading: true };

    case "HIDE":
      return { state, loading: false };

    default:
      return state;
  }
};

export default loadingOverlay;
