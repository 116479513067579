import React from "react";

export default function Info() {
  return (
    <>
      <h1 style={{ marginBottom: "10%" }}>About</h1>
      <h2>
        <i>Daryl Crosbie</i>
      </h2>
      <h3 style={{ marginBottom: "10%" }}>Software Developer</h3>
      <p>From Dublin, Ireland; based in Perth, Western Australia.</p>

      <p>
        I enjoy developing my skills in full stack web development with the
        latest technologies and creating mobile apps for Android and iOS.
      </p>
      <p>
        AWS Certified Developer - Associate.
      </p>
    </>
  );
}
