
export const showLoader = () => (dispatch) => {
  dispatch({
    type: "SHOW",
  });
};
export const hideLoader = () => (dispatch) => {
  dispatch({
    type: "HIDE",
  });
};
