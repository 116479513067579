import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import AppDetails from "./app-details/app-details";
import { showLoader, hideLoader } from "../../actions/application";
import AppImg from "./app-img/app-img";
import "./selected-app.css";

export default function SelectedApp() {
  const dispatch = useDispatch();

  const [count, setCount] = useState(0);
  const [amount, setAmount] = useState(0);
  if (count === 0) {
    dispatch(showLoader());
  }
  let { appTitle } = useParams();

  const handleLoader = (imgCount) => {
    if (imgCount > 0) {
      setAmount(imgCount + 1);
    }
    const c = count + 1;
    if (c === amount) {
      setTimeout(() => {
        dispatch(hideLoader());
      }, 500);
    }
    setCount(c);
  };

  return (
    <div className="selected">
      <div className="details-container">
        <AppDetails appTitle={appTitle} loader={handleLoader} />
      </div>
      <div className="images-container">
        <AppImg
          appTitle={appTitle}
          loader={(imgCount) => handleLoader(imgCount)}
        />
      </div>
    </div>
  );
};
