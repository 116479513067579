import React, { useContext, useState} from "react";
import { Lang, useFormInputValidation } from "react-form-input-validation";
import Button from "react-bootstrap/Button";
import emailjs from '@emailjs/browser';
import { Context } from "../../screenContext";
import CircularProgress from '@mui/material/CircularProgress';


const ContactForm = () => {

  const [send, setSend] = useState(false);

  const [fields, errors, form] = useFormInputValidation(
    {
      name: "",
      email: "",
      message: "",
    },
    {
      name: "required",
      email: "required|email",
      message: "required",
    }
  );

  form.useLang(Lang.en);

  const onSubmit = async (event) => {
    setSend(true);
    const isValid = await form.validate(event);

    if (isValid) {
      console.log("Calling emailJS", fields, errors);
      emailjs
        .send("myGmail", "template_tome", fields, "user_SBAGatrLxdCXx7GPAAO4H")
        .then(function (res) {
          console.log("Success");
          alert("Message Sent.");
          setSend(false);
        })
        .catch(function (err) {
          console.error("Failed", err);
          alert("Message Not Sent");
          setSend(false);
        });
    }else{
      setSend(false)
    }
  };


  const screen = useContext(Context);

  return (
    <form onSubmit={onSubmit}>
      <div className="form-wrapper">
        <div>
          <div className="form-group-row">
            <label htmlFor="name">Name</label>

            <input
              type="text"
              name="name"
              className="form-control"
              onBlur={form.handleBlurEvent}
              onChange={form.handleChangeEvent}
              value={fields.name}
            />

            <label className="error">{errors.name ? errors.name : ""}</label>
          </div>
          <div className="form-group-row">
            <label htmlFor="inputEmail">E-mail address</label>
            <input
              type="email"
              name="email"
              className="form-control"
              onBlur={form.handleBlurEvent}
              onChange={form.handleChangeEvent}
              value={fields.email}
            />

            <label className="error">{errors.email ? errors.email : ""}</label>
          </div>
        </div>
        <div className="message-box">
          <div className="form-group-row">
            <label htmlFor="message">Message</label>
            <textarea
              type="text"
              name="message"
              className="form-control"
              rows={screen.height > 450 ? 5 : 4}
              onBlur={form.handleBlurEvent}
              onChange={form.handleChangeEvent}
              value={fields.message}
            />
            <label className="error">
              {errors.message ? errors.message : ""}
            </label>
          </div>
          <div className="form-group-row">
            {send ?  <CircularProgress /> : <Button
              type="submit"
              variant="outline-light"
              size={screen.width > 1000 ? "lg" : screen.width > 450 ? "" : "sm"}
            >
              Send
            </Button>}
          </div>
        </div>
      </div>
    </form>
  );
};


export default ContactForm;
