import Tap from "../../../images/app-logo/tap.png";
import Exchange from "../../../images/app-logo/exchange.png";
import Liberty from "../../../images/app-logo/liberty.png";

export const APP_DETAILS = [
  {
    title: "Liberty",
    logo: Liberty,
    platform: "Android / iOS",
    desc: `Liberty is a mobile version of a classic friendly card game.\nIt was developed with the flutter SDK, making it compatible with Andoid, iOS and Web Apps.\nThe game can be played offline, and online with up to eight players per game.`,
    store:
      "https://play.google.com/store/apps/details?id=com.digitalcontriver.liberty",
    code: null,
  },
  {
    title: "D-Exchange",
    logo: Exchange,
    platform: "Android",
    desc: "A clean and simplistic style currency converter.\nThis application was developed with Koltlin in Android Studio.",
    store: "https://play.google.com/store/apps/details?id=dev.daryl.d_exchange",
    code: "https://github.com/daryl-crosbie/Android-Java-Kotlin-/tree/master/D-Exchange%20Currency%20Converter%20(MVVM%2C%20Firebase)/src",
  },
  {
    title: "D-Tap",
    logo: Tap,
    platform: "Android",
    desc: "A classic arcade style game reinterpret.\nThis application was developed with Kotlin in Android Studio.",
    store: "https://play.google.com/store/apps/details?id=dev.daryl.d_tap",
    code: "https://github.com/daryl-crosbie/Android-Java-Kotlin-/tree/master/D-Tap%20Game",
  },
];
