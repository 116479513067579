import React, { useState } from "react";
import "./skills.css";

export default function Skills(props) {
  const [hover, setHover] = useState(false);
  return (
    <div className="skill-container">
      <a
        href={props.tech.url}
        target="_blank"
        rel="noopener noreferrer"
        className="tech-name"
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => setHover(false)}
      >
        {props.tech.name}
      </a>
      <img
        src={props.tech.logo} 
        onLoad={props.loaded}
        className={hover ? "tech-logo" : "static-logo"}
        alt="logo"
      />
    </div>
  );
}
