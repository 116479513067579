import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Image from 'react-bootstrap/Image';

export default function ControlledCarousel(props) {
  const [img] = useState(props.images);
  const [index, setIndex] = useState(0);

  const carouselItemList = [];

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  for (const [index, value] of img.entries()) {
    carouselItemList.push(
      <Carousel.Item key={index}>
        <Image
          className="d-block w-100"
          src={value}
          onLoad={props.loader}
        />
      </Carousel.Item>
    );
  }

  return (
    <Carousel fade interval={2000} activeIndex={index} onSelect={handleSelect}>
      {carouselItemList}
    </Carousel>
  );
}
